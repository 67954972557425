$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~material-icons/iconfont/material-icons.css';
@import 'variables';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'splash-screen-loading';

.circle {
    background-color: $base-color;
    border-radius: 50%;
    width: 5em;
    height: 5em;
}

body {
    margin-top: -20px;
    font-size: 16px !important; // Set default instead of browsers default for responsive EM units
}

h1,
h2,
h3,
h4 {
    color: $base-color;
}

a {
    cursor: pointer;
}

button {
    outline: 0 solid transparent !important;
}

/* REMOVE FOCUS FROM INPUT FIELDS */
// input:focus, textarea:focus, select:focus {
//     outline-offset: 0px;
// }
// .form-control:focus {
//     // border-color: #66afe9;
//     outline: 0;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.00), 0 0 8px rgba(102, 175, 233, 0.0);
// }

.input-error-message {
    font-size: 0.8em;
    overflow: visible;
    white-space: nowrap;
}

label.required-label:after {
    content: ' *';
    color: $label-warning;
}

// EMPTY REQUIRED FIELDS WARNING
input.ng-invalid,
.custom-select.ng-invalid {
    border: 1px solid $label-warning !important;
}

input.ng-invalid:focus {
    border-color: $label-warning;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($label-warning, 0.6);
}

textarea.ng-invalid,
.custom-select.ng-invalid {
    border: 1px solid $label-warning !important;
}

textarea.ng-invalid:focus {
    border-color: $label-warning;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($label-warning, 0.6);
}

.btn-default,
.btn-default:hover,
.active {
    background-color: $base-color !important;
    color: #fff !important;
}

.allign-button {
    margin-top: 25px;
}

.form-group-container {
    margin: 5px 0px;
    padding: 5px;
    border: 1px solid $base-color;

    legend {
        width: 30%;
        padding: 0.2em 0.5em;
        border: 1px solid $base-color;
        color: $base-color;
        // font-size:0.9em;
        @include font-size(0.9);
    }

    legend:hover {
        color: white;
        background-color: $base-color;
    }

    .content {
        display: none;
    }
}

/* borders */
.b-1-base {
    padding: 0.5em;
    border: 1px solid $base-color;
}

/* width in % */
.w-40 {
    width: 40%;
}

/* margins */
.m-top-2 {
    margin-top: 2%;
}

.m-top-5 {
    margin-top: 5%;
}

.m-top-10 {
    margin-top: 10%;
}

.m-top-20 {
    margin-top: 20%;
}

.m-right-5 {
    margin-right: 5%;
}

/* NO SCROLLBARS */
::-webkit-scrollbar {
    display: none; //Chrome / Safari
    width: 0px;
    background: transparent;
}

html,
body {
    -ms-overflow-style: none !important; // IE 10+
    overflow: -moz-scrollbars-none !important; // Firefox
    @include browser(Firefox) {
        height: calc(100vh + 20px);
    }
}

/* MODAL FIX */
.modal-backdrop {
    z-index: -1 !important;
}

.modal {
    &.fade {
        &.in {
            background-color: rgba(0, 0, 0, 0.66);
        }
    }
}

/* CUSTOM BUTTON */
.confirm-button-custom {
    @include border-radius(22px);
    background-color: $button !important;
    border-color: $button !important;
    border-style: none;
    padding: 8px 24px;
    margin-top: 15px;
    @include font-size(1.3);
    font-weight: normal;
    color: $label-color;
    @include transition(all 0.3s ease);

    &:hover {
        background-color: $button-hover !important;
        border-color: $button-hover !important;
    }
}
