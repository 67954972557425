@import "../../../../../../assets/variables";

$main-color: #004aa0;
$base-color: #004aa0;

$font-stack: Helvetica, sans-serif;
$font-size-base: 14px;
// $image-path: '/assets/images' !default; @deprecated (see: https://github.com/angular/angular-cli/issues/14587#issuecomment-497368020)
$publishers-all: 'gmb' 'pagesjaunes' 'googlemaps' 'facebook' 'bing' 'apple'
    'yahoo' 'foursquare' 'petitfute' '118000' '123pages' 'waze_navads' 'here'
    'tomtom' 'navmii' 'seety' 'pharmanity' 'site_privilege' 'factual_navads'
    'soms' 'here_navads' 'garmin' 'bingplaces' 'navads' 'mappy' 'uber_navads'
    'tripadvisor' 'ooreka' 'qwant' 'apple_navads' 'justacote_navads'
    'pages24_navads' 'horaire_navads' 'annuaire_navads' 'bing_navads' 'instagram'
    'trouverouvert_navads' 'horairesdouverture24_navads' 'linkedin2' 'twitter2';
$publishers-3main: 'facebook' 'gmb' 'pagesjaunes';
$statuses: 'rejected' 'pending' 'not_connected' 'active' 'error'
    'do_not_connect' 'deleted' 'duplicate' 'inactive' 'pending' 'oauth_needed';

$title-color: $base-color;
$label-background-color: $base-color;
$link-color: $base-color;
$pagination-color: $base-color;
$pagination-active-border-color: $base-color;
$pagination-active-bg: $base-color;
$pagination-active-color: #fff;
$pagintaion-holder-bg: #f0f0f0;
$pagination-holder-border: #e7e7e7;

$address-color: #626262;
$label-color: #fff;
$label-warning: #d22424;
$background: #1562bc;
$main-alpha-66: rgba(#004a9f, 0.66);
$location-list-header: #4986cc;
$location-list-bg-color: #fff;
$locations-title: #0075cf;
$location-selected: #f1f7ff;
$button: #00d9a3;
$button-hover: #09c696;
$link: #ffffff;
$link-hover: #cfcfcf;
$headings: #ffffff;
$placeholder: #abaaaa;
$result-background: #c7c7c7;
$result-current-background: #a5a5a5;
$percentage-label: #00d9a3;
$tabs-bg: #f0f0f0;
$tab-titles: #4a4a4a;
$tab-titles-shadow: #000;
$input-focus: #fffcd5;
$dropdown-active: #eeeeee;
$remove-field-btn: $base-color;
$remove-field-btn-bg: #fff;
$custom-select-bg: $base-color;
$custom-select-text: white;
$datepicker-input-bg: white;
$custom-checkbox-bg: white;
$custom-checkbox-border: $base-color;
$custom-checkbox-tick: $base-color;
$custom-checkbox-tick-hover: #4a4a4a;
$custom-checkbox-label: #4a4a4a;
$image-upload-bg: $base-color;
$image-upload-bg-hover: #4986cc;
$image-item-hover: $base-color;
$publisher-disconnect-link: #ff0000;
$publisher-view-link: #56c224;
$tooltip-bg: #fff;
$menu-burger: #fff;
$menu-sidebar-text: #fff;
$menu-sidebar-bg: $base-color;
$screen-shade: rgba(0, 0, 0, 0.7);
$status-pending: #9b9b9b;
$status-not_connected: #ebaa00;
$status-oauth_needed: #385995;
$status-active: #56c224;
$status-rejected: #ff0000;
$status-error: #ff0000;
$fb-tutorial-indicators: #acacac;
$fb-tutorial-active-indicator: $base-color;
$fb-tutorial-nav: $base-color;
$fb-tutorial-description: #4a4a4a;

$modal-header-bg: #4986cc;

$kpi-text-color: #707070;
$kpi-percentage-up-color: #19d77d;
$kpi-percentage-down-color: #f03d3d;
$kpi-table-header-color: #f1f2f4;
$kpi-table-row-divider-color: #d2d2dc;
$kpi-table-header-alt-color: #fafafa;
