@import "../../../../../../assets/mixins";
@import "variables";

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
@mixin background-size($bgsize) {
    -webkit-background-size: $bgsize !important;
    -moz-background-size: $bgsize !important;
    -o-background-size: $bgsize !important;
    background-size: $bgsize !important;
}
@mixin opacity($value) {
    $IEValue: $value * 100;
    opacity: $value;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + $IEValue +
        ')';
    filter: alpha(opacity=$IEValue);
}
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}
// REM => PX font size fall down
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 16) + px;
    font-size: $sizeValue + rem;
}
// I like to move it, move it
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}
@mixin animation-delay($str) {
    -webkit-animation-delay: #{$str};
    -moz-animation-delay: #{$str};
    -ms-animation-delay: #{$str};
    -o-animation-delay: #{$str};
    animation-delay: #{$str};
}
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
// rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}
// translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}
/* For browser dependent stuff */
@mixin browser($browsers: 'Mozilla') {
    @each $browser in $browsers {
        html[data-browser*='#{$browser}'] & {
            @content;
        }
    }
}

//*** Media queries ***//
// Width (base pixel size: 20px)
// ~ 2140px width
@mixin colossal-width {
    @media only screen and (max-width: 107em) {
        @content;
    }
}
// ~ 1920px width
@mixin enormous-width {
    @media only screen and (max-width: 96em) {
        @content;
    }
}
// ~ 1780px width
@mixin xx-large-width {
    @media only screen and (max-width: 89em) {
        @content;
    }
}
// ~ 1660px width
@mixin x-large-width {
    @media only screen and (max-width: 83em) {
        @content;
    }
}
// ~ 1340px width
@mixin large-width {
    @media only screen and (max-width: 67em) {
        @content;
    }
}
// ~ 1220px width
@mixin normal-plus-plus-width {
    @media only screen and (max-width: 61em) {
        @content;
    }
}
//~ 1140px width
@mixin normal-plus-width {
    @media only screen and (max-width: 57em) {
        @content;
    }
}
// ~ 980px width
@mixin normal-width {
    @media only screen and (max-width: 49em) {
        @content;
    }
}
// ~ 760px width
@mixin small-width {
    @media only screen and (max-width: 38em) {
        @content;
    }
}
// ~ 480px width
@mixin tiny-width {
    @media only screen and (max-width: 24em) {
        @content;
    }
}
// ~ 340px width
@mixin microscopic-width {
    @media only screen and (max-width: 17em) {
        @content;
    }
}

// Height (base pixel size: 16px)
// ~ 960px height
@mixin high-rider {
    @media only screen and (max-height: 60em) {
        @content;
    }
}
// ~ 832px height
@mixin mid-rider {
    @media only screen and (max-height: 52em) {
        @content;
    }
}
// ~ 720px height
@mixin low-rider {
    @media only screen and (max-height: 45em) {
        @content;
    }
}
