@import './variables';
@import './mixins';

*{
    margin: 0;
    padding: 0;
}

body, html{
    height: 100%;
}

.angular-prebootstrap-loading-container {
    height: calc(100vh + 20px);
}

.angular-prebootstrap-loading {
    background-color: $main-color;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loading-footer {
    position: fixed;
    bottom: 5%;
    color: $headings !important;
    width: 100%;
    .loading-copyright {
        display: inline-block;
        float: left;
        padding-left: 2%;
        margin-top: 3px;
    }
    .loading-powered-by {
        display: inline-block;
        float: right;
        padding-right: 2%;
        p {
            display: inline-block;
        }
        .loading-footer-logo {
            display: inline-block;
            margin: -20px 0 0 5px;
        }
    }
}

.sk-wave {
    margin: 40px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    // font-size: 10px;
    @include font-size(1.0);
}

.sk-wave .sk-rect {
    background-color: #fff;
    height: 100%;
    width: 6px;
    display: inline-block;
    @include animation('sk-waveStretchDelay 1.2s infinite ease-in-out');
}

.sk-wave .sk-rect1 {
    @include animation-delay('-1.2s');
}

.sk-wave .sk-rect2 {
    @include animation-delay('-1.1s');
}

.sk-wave .sk-rect3 {
    @include animation-delay('-1s');
}

.sk-wave .sk-rect4 {
    @include animation-delay('-0.9s');
}

.sk-wave .sk-rect5 {
    @include animation-delay('-0.8s');
}

@include keyframes (sk-waveStretchDelay) {
    0%, 40%, 100% {
            transform: scaleY(0.4);
    }
    20% {
            transform: scaleY(1);
    }
}
